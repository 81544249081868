import React from "react"
import { ThemeProvider } from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Navigation from "sections/Navigation"
import BannerClassic from "sections/Banner-classic"
import FeaturesClassic from "sections/Features-classic"
import Footer from "sections/Footer-classic"

import { GlobalStyle } from "sections/app.style"
import theme from "theme/classic/themeStyles"

import AppScreenImage from "assets/images/app/app-screen/pomo.png";

import Icon1 from "assets/images/app/app-feature/1.svg";
import Icon2 from "assets/images/app/app-feature/2.svg";
import Icon3 from "assets/images/app/app-feature/6.svg";
import Icon4 from "assets/images/app/app-feature/5.svg";
import Icon5 from "assets/images/app/app-feature/4.svg";
import Icon6 from "assets/images/app/app-feature/7.svg";

class FeatureInfo {
  constructor(title, detail, icon) {
    this.title = title;
    this.detail = detail;
    this.icon = icon;
  }
}

const MiniPomoPage = () => (
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    <Layout>
      <SEO title="Appion Gatsby Home" />
      <Navigation pageName="Pomo" />
      <BannerClassic 
        title="Stay focused" appName="Mini Pomo" 
        detail="Stay focused, easy reach goals"
        appScreenImage={AppScreenImage}
        link_web = "/miniPomo#appFeatures"
        link_apple = "https://apps.apple.com/us/app/mini-pomo-focus-timer/id1552213266"
      />
      <div id="appFeatures">
        <FeaturesClassic 
          appName="Mini Pomo"
          itemGroup={[
              new FeatureInfo("Cloud syncing", "Automatically sync with iCloud",Icon1),
              new FeatureInfo("Pomo time", "Stay focused", Icon2),
              new FeatureInfo("Pomo category", "Organize with category", Icon3),
              new FeatureInfo("Pomo settings", "Customize pomo and rest", Icon4),
              new FeatureInfo("Surprise !", "Let’s see what’s behind the UI", Icon5),
              new FeatureInfo("Pomo Stastics", "pomo number and time", Icon6)
            ]} 
        />
      </div>
      <Footer privacyLink="/Privacy/privacy_en" termsLink="/Terms/terms_en" />
    </Layout>
  </ThemeProvider>
)
export default MiniPomoPage